<template>
  <div>
    <h3>创建一个图床仓库</h3>
    <p>创库创建完成之后设置新建一个Releases</p>
    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/repo1.png"
      alt="img"
      style="zoom: 50%"
    />
    <br>
    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/repo2.png"
      alt="img"
      style="zoom: 33%"
    />

    <p>随便输入一个版本号，回车即可！！！</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>