<template>
  <div>
      <h3>拿到TOKEN</h3>
       <p>
      1.打开链接
      <a target="_blank" href="https://github.com/settings/tokens"
        >https://github.com/settings/tokens</a
      >
      点击 Generate new token 按钮
    </p>
    <p>
      2.在打开的 <b>New personal access token</b> 中选中"repo"和"user",如下所示
    </p>
    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/getToken.png"
      alt=""
    />
    <p>3.然后点击 Generate token 按钮，即可生成一个token，如下：</p>
    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/token.png"
      alt=""
    />
    <p style="color: red"><em>​ 生成的TOKEN只会显示一次。记得保存！！</em></p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>