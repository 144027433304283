<template>
  <div>
    <h3>使用</h3>
    <p>先设置用户token和仓库路劲</p>

    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/set.png"
      alt="img"
      style="zoom: 50%"
    />

    <p>使用</p>

    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/0f0765da7cbf3fbd482134d06a13d822.png"
      alt="img"
      style="zoom: 50%"
    />

    <p>列表</p>

    <img
      src="https://cdn.jsdelivr.net/gh/WishMelz/file/image/4af4a12a816480b138bbd2ea27871438.png"
      alt="img"
      style="zoom: 50%"
    />
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>